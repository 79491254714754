import { useState, useEffect, useRef } from 'react';
import { useUser } from '@app/helpers/hooks';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_CONVERSATIONS, MESSAGES_QUERY } from '@app/apollo/queries';
import echo from '@app/config/laravel-echo';

export function useUserInboxState() {
  const { user: authUser } = useUser();

  const [getUserConversations ,{ data: { userConversations } = [] }] = useLazyQuery(GET_CONVERSATIONS);
  useEffect(() => {
    getUserConversations();
  }, []);

  const [fetchUserMessages, { data }] = useLazyQuery(MESSAGES_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [userInbox, setUserInbox] = useState({
    conversations: [],
    activeConversation: null,
    activeChatUser: null,
    messages: [],
    globalUnreadCount: 0,
    unreadCounts: {},
  });

  const userInboxRef = useRef(userInbox);

  useEffect(() => {
    userInboxRef.current = userInbox;
  }, [userInbox]);

  const getUserMessages = (conversation) => {
    fetchUserMessages({
      variables: {
        conversationId: conversation.id,
      },
    });
  }

  const updateGlobalUnreadCount = () => {
    const count = Object.values(userInbox.unreadCounts).reduce((sum, val) => sum + val, 0);
    setUserInbox((prevUserInbox) => ({
      ...prevUserInbox,
      globalUnreadCount: count,
    }));
  }
  useEffect(() => {
    updateGlobalUnreadCount()
  }, [userInbox.unreadCounts]);

  const setMessages = (messageList) => {
    setUserInbox((prevValues) => ({
      ...prevValues,
      messages: messageList,
    }));
  };

  useEffect(() => {
    if (data) {
      setMessages(data.userMessages);
    }
  }, [data]);

  const setActiveConversation = (conversation) => {
    setUserInbox((prevUserInbox) => ({
      ...prevUserInbox,
      activeConversation: conversation,
    }));
  };

  const setActiveChatUser = (friend) => {
    setUserInbox((prevUserInbox) => ({
      ...prevUserInbox,
      activeChatUser: friend,
    }));
  };

  const resetUnreadCount = (conversationId) => {
    setUserInbox((prevUserInbox) => {
      const updatedUnreadCounts = { ...prevUserInbox.unreadCounts };
      updatedUnreadCounts[conversationId] = 0;
      
      return {
        ...prevUserInbox,
        unreadCounts: updatedUnreadCounts,
      };
    });
  };

  const incrementUnreadCount = (conversationId, value = 1) => {
    setUserInbox((prevUserInbox) => ({
      ...prevUserInbox,
      unreadCounts: {
        ...prevUserInbox.unreadCounts,
        [conversationId]: (prevUserInbox.unreadCounts[conversationId] || 0) + value,
      },
    }));
  };

  const pushToMessages = (message) => {
    setUserInbox((prevUserInbox) => ({
      ...prevUserInbox,
      messages: [...prevUserInbox.messages, message],
    }));
  };

  const pushToConversationList = (conversation) => {
    setUserInbox((prevUserInbox) => ({
      ...prevUserInbox,
      conversations: [...prevUserInbox.conversations, conversation],
    }));
  };

  const setConversationList = (conversations) => {
    setUserInbox((prevUserInbox) => ({
      ...prevUserInbox,
      conversations,
    }));
  };

  const contactScientist = (scientist) => {
    const existingConversation = userInbox.conversations.find(
      (conversation) =>
        conversation.user.id === scientist.id || conversation.friend.id === scientist.id
    );

    if (!existingConversation) {
      setActiveConversation({ id: 0, friend: scientist });
    } else {
      setActiveConversation(existingConversation);
      getUserMessages(existingConversation);
    }
  };

  const chatChannel = echo?.channel('chat');

  const joinChatChannel = () => {
    echo
      .join('chat')
      .here((users) => {
        // console.log(`all users:`);
        // console.log(users);
      })
      .joining((user) => {
        // console.log('joining user:');
        // console.log(user);
      })
      .leaving((user) => {
        // console.log('leaving user:');
        // console.log(user);
      })
      .error((error) => {
        console.error(error);
      });
  };

  const listenForNewMessage = (conversation) => {
    echo.channel(`chat.${conversation.id}`)
    .listen('NewMessage', (e) => {
      if (e.message?.receiver_id === authUser?.id) {
        if (parseInt(e.message?.conversation_id, 10) === parseInt(userInboxRef.current.activeConversation?.id, 10) ) {
          pushToMessages(e.message);
        } else {
          incrementUnreadCount(e.message?.conversation_id);
        }
      }
    });
  };

  const listenForNewConversation = () => {
    chatChannel.listen('NewConversation', (e) => {
      if (e.conversation?.friend_id === authUser?.id) {
        pushToConversationList(e.conversation);
        // incrementUnreadCount(e.conversation.id);
      }
    });
  };

  useEffect(() => {
    if (userInbox.activeConversation) {
      if (userInbox.activeConversation.id === 0) {
        setActiveChatUser(userInbox.activeConversation.friend);
      } else {
        setActiveChatUser(
          userInbox.activeConversation?.user?.id === authUser.id
            ? userInbox.activeConversation.friend
            : userInbox.activeConversation.user
        );

        getUserMessages(userInbox.activeConversation);
      }
    }
  }, [userInbox.activeConversation]);

  useEffect(() => {
    if (userConversations) {
      setConversationList(userConversations);
    }
  }, [userConversations]);

  useEffect(() => {
    if (authUser && echo) {
      joinChatChannel();
      listenForNewConversation();
    }
    return () => {
      chatChannel?.stopListening('NewConversation');
    };
  }, [authUser]);

  useEffect(() => {
    if(echo) {
      userInbox.conversations?.forEach((conversation) => {
        listenForNewMessage(conversation);
      });
    }
  }, [userInbox.conversations, userInbox.conversations.activeConversation, authUser]);

  return {
    authUser,
    userInbox,
    userInboxRef,
    setUserInbox,
    pushToMessages,
    resetUnreadCount,
    setActiveConversation,
    setActiveChatUser,
    pushToConversationList,
    setConversationList,
    incrementUnreadCount,
    contactScientist,
    getUserConversations,
    getUserMessages,
  };
}
